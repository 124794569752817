import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const LaCompagnie = () => (
  <Layout>
    <SEO title="La compagnie" description="La compagnie Ailes de Cire propose des ateliers chorégraphiques, ainsi que des cours de danse, ouverts à tout types de publics et de corps."/>
    <section id="compagnie-banner">
      <h1>LA COMPAGNIE</h1>
    </section>
    <section className="main container">

      <h2>Présentation</h2>

      <p>La compagnie Ailes de Cire propose des ateliers chorégraphiques, ainsi que des cours de danse, ouverts à tout types de publics et de corps. Elle regroupe un réseau d’artistes au parcours atypique et pluridisciplinaire. Au travers de nos créations, il nous tient à cœur de sublimer le lien qui nous unit en tant qu’humain.</p>
      <p>Ailes de Cire s’inspire du quotidien, et propose des formes insolites, des corps surréalistes, des contorsions oniriques. Chaque création est un tableau qui prend vie sous vos yeux. Un savant mélange pour inviter à la rêverie, à la poésie, nos artistes interprètes se métamorphosent, et deviennent matière sensible animée par la force de l’imaginaire. Questionner les images qui nous composent, et offrir un brin de poésie dans un monde parfois hostile.</p>
      <p><strong>Nos créations et notre travail de recherche cherchent à défendre un mouvement :</strong></p>
      <p className="citations">« qui touche en plein cœur,<br />qui repousse sans cesse ses limites<br />qui chatouille celui qui regarde<br />qui cherche des horizons toujours plus lointain. »</p>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: `100%`, margin: `0`, padding: `0`}} width="1921.046" height="136.58" viewBox="0 0 1921.046 136.58">
        <path id="Tracé_10" data-name="Tracé 10" d="M7960,1363c915.685-43.829,1394.665-76.561,1645.546-98.86C9881.02,1239.654,9880,1227.819,9880,1227.819" transform="translate(-7959.953 -1227.416)" fill="none" stroke="#2d2c2b" strokeWidth="2"/>
    </svg>
    <section className="main container">
      <h2>Les intervenants</h2>
      <div className="row align-items-center">
        <div className="col-md-4 col-sm-12 mb-3">
            <Image filename="Clara_COMPAIN_3.jpg" alt="Clara COMPAIN, Chorégraphe & Interprète" title="Clara COMPAIN, Chorégraphe & Interprète" />
        </div>
        <div className="col-md-8 col-sm-12">
          <h3>Clara COMPAIN<i> - chorégraphe et interprète</i></h3>
          <p>Originaire de la Rochelle, c’est auprès de Pascale Mayeras, Virginie Garcia et Anne Journo que Clara débute la danse. Elle suit une formation de danseuse interprète à Nice auprès de Marie Pierre Genovese et Bernadette Vissyrias en Ballet, Jazz et Danse contemporaine. Une rencontre avec Lisie Philip la pousse à suivre les trainings de la Compagnie Antipodes pendant quelques années.</p>
          <p>Dans l’espace urbain, face à l’immensité du ciel elle trouve une liberté et un cadre qui lui permet de cultiver un imaginaire singulier. Tout au long de sa formation elle développe un travail centré sur l’humain et le dépassement de soi au travers de la poésie du mouvement.</p>
          <p>Elle obtient en 2016 une Licence Transmission en Danse à l’Université de Nice.</p>
          <p>Curieuse et aventurière, elle part pendant un an à Séoul achever sa formation à SEOUL TANZ STATION. Elle s’essaye aux danses locales et collabore avec les danseurs de la Tatmaroo Dance Company. Elle cultive un travail de corps axé sur une forte physicalité et une oscillation entre fluidité et explosivité.
      À son retour, elle crée la Compagnie Ailes de Cire et poursuit une formation de professeur de danse contemporaine avec l’objectif de rendre accessible la danse à tout type de public et de corps.
      Elle danse dans SWAG de Samuel Mathieu, Purgatoire et Paradis Perdus de Pierre Rigal, The Moebius Strip de Gilles Jobin, adapté par Jean Pierre Bonomo.
      Clara est toujours à la recherche d’un engagement plus intense, d’un partage d’émotions et de sensations. Sur scène comme en cours, elle questionne le potentiel de chaque corps et cherche a sublimer l’énergie, ce souffle intérieur qui anime chacun et donne sens à notre existence.</p>
        </div>
      </div>
      <div className="row align-items-center" style={{marginBottom: `2rem`, marginTop: `2rem`}}>
        <div className="col-md-8 col-sm-12 order-2 order-sm-2 order-md-1">
          <h3 className="text-right">Yannick BERBIE<i> - compositeur et interprète</i></h3>
          <p>Passionné par la corporalité et l’engagement physique de la
          percussion, Yannick développe autour de la batterie hybride un jeu
          instinctif, organique, basé sur les notions d’écoute, d’improvisation
          et d’état d’urgence.</p>
          <p>Trio de Jazz, Quartet de musiques du monde, Chorale de Gospel,
          Chanson réaliste, Trio de percussions, collectif pluri-disciplinaire, en
          parallèle de ses expériences musicales variés Yannick explore le
          mouvement depuis son enfance : théâtre, danse classique, danse
          contemporaine.</p>
          <p>Aujourd’hui Yannick compose et interprète les musiques pour les
          créations de la Compagnie Empreintes (danse contemporaine /
          musique live), et participe aux ateliers en lien avec les créations
          (parcours culturels, Danse à l’école, Chemin des Arts, Rencontres
          danse-musique)</p>
          <p>
            <a href="https://musiquepourladanse.com/" target="_blank" rel="noreferrer">Voir son site internet musiquepourladanse.com</a>
          </p>
        </div>
        <div className="col-md-4 col-sm-12 mb-3 order-1 order-sm-1 order-md-2">
            <Image filename="Yannick-Berbie.jpg" alt="Yannick BERBIE, Compositeur et interprète" title="Yannick BERBIE, Compositeur et interprète" />
        </div>
      </div>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: `100%`, margin: `0`, padding: `0`}} width="1921.047" height="139.391" viewBox="0 0 1921.047 139.391">
      <path id="Tracé_11" data-name="Tracé 11" d="M7960,1227.819c915.685,44.744,1394.665,78.159,1645.546,100.923,275.473,25,274.453,37.077,274.453,37.077" transform="translate(-7959.952 -1226.821)" fill="none" stroke="#2d2c2b" strokeWidth="2"/>
    </svg>
    <section className="main container">
      <h2>Nos objectifs</h2>

      <div className="text-right mb-5">
        <p className="citations">« Il y a dans ce monde des jardiniers invisibles qui cultive les rêves des autres »</p>
        <p><strong>Jeanne Benameur</strong></p>
      </div>

      <h3>La compagnie Ailes de Cire a plusieurs missions :</h3>
      <ul className="mb-5 p-0">
        <li className="ml-5"><strong>Créer des spectacles au format atypique, pour tout types de terrain et de public :</strong></li>
        <p>Nous cherchons au travers de nos créations à sans cesse aller vers de nouveaux horizons, les interprètes investissent autant des espaces naturels ou publics, que des scènes plus conventionnels. Performance, pièce chorégraphique, flash mob, manifeste, et vidéodanse… Les créations sont parfois inclassables.</p>

        <li className="ml-5"><strong>Encourager la pluridisciplinarité, et l’innovation :</strong></li>
        <p>La compagnie collabore avec des artistes au parcours singulier, peintres, illustrateurs, danseurs, musiciens, circassiens, sportifs, costumiers. La parole peut aussi être donné à artistes du quotidien, qui n’ont pas une formation artistique. Sublimer par le mouvement la vie, nos créations viennent chatouiller notre quotidien et dresser le portrait d’anonymes, et s’inspirent de moments vécus.</p>

        <li className="ml-5"><strong>Défendre une danse accessible à tous :</strong></li>
        <p>Les ateliers s’adressent à tous. La compagnie met en place des actions afin d’emmener la danse aux personnes en situation de handicap, ou dépendantes. Il nous tient à cœur de croiser les générations, c’est pourquoi nos stages et workshops sont ouverts à tous sans tranche d’âge.</p>

        <li className="ml-5"><strong>Cultiver la curiosité et les échanges internationaux :</strong></li>
        <p>La compagnie Ailes de Cire met en place des partenariats avec des institutions culturelles à l’étranger et propose des workshops sur tout les continents (Sauf l’antarctique bien sûr). Nous cherchons à interagir avec les acteurs culturelles présent en Asie (Japon, Corée du Sud et Chine).</p>

        <li className="ml-5"><strong>Proposer des moments d’échanges et de rencontre avec le public et les artistes :</strong></li>
        <p>Des actions de médiations culturelles sont proposés avant et après les évènements proposés par la compagnie. Des moments de rencontre sont proposés dans les écoles, collèges et lycées afin de cultiver l’esprit critique, et élargir les connaissances culturelles de notre auditoire. Nous proposons régulièrement des performances in situ où la compagnie vient à la rencontre des passants.</p>

        <li className="ml-5"><strong>Enseigner la danse et accompagner les talents de demain à s’élancer :</strong></li>
        <p>Nous apportons notre soutien aux jeunes artistes qui souhaitent créer des projets, en facilitant l’accès à des studios, ou en les encourageant à développer leur réseaux, tout en les redirigeant vers des institutions qui correspondent à leur projet.</p>

        <li className="ml-5"><strong>Rendre accessible les savoirs :</strong></li>
        <p>Un fond documentaire est disponible sur rendez vous au siège de la Cie en fonction des demandes (Vidéos, articles, et ouvrages spécialisée en Danse et Arts Vivants.)</p>

      </ul>
      <p>Si vous avez une demande spécifique, n’hésitez pas à <a href="mailto:cieailesdecire@gmail.com">nous contacter</a>.</p>
    </section>
  </Layout>
)

export default LaCompagnie
